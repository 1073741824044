<template>
  <v-container text-center>
    <v-layout row wrap>
      <v-flex xs10 offset-xs1>
        <v-icon class="sucess_icon my-5" color="red">error_outline</v-icon>
      </v-flex>
    </v-layout>
    <v-layout row wrap>
      <v-flex xs10 offset-xs1> <h3>Unable to process!</h3> </v-flex>

      <v-flex xs10 offset-xs1>
        <p class="my-5 caption">{{ this.$route.query.message }}</p>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {}
</script>
<style scoped>
.sucess_icon {
  font-size: 50px;
}
</style>
